import React from "react";
import Layout from "../components/layout";

const NotFoundPage = () => (
  <Layout title={"404: Fant ikke siden du lette etter"}>
    <div
      style={{
        maxWidth: "1440px",
        width: "100%",
        margin: "5em auto",
        textAlign: "center"
      }}
    >
      <h1>Glemte du brillene<span role="img" aria-label="briller">👓</span>?</h1>
      <p>
        Nå har du gått deg vill. Du er på vei til en side som ikke finnes. Kanskje det er på tide med en ny synstime?
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
